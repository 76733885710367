<template>
  <div id="container" class="w-full h-full flex flex-col justify-between">
    <header id="header" class="head-container bg-[#FC0A1A]">
      <div class="flex items-center justify-end lg:justify-start">
        <img
          class="w-3/5 sm:w-4/5 h-auto"
          src="../assets/mimiko-logo.png"
          alt="logo"
        />
      </div>
      <div class="nav-content">
        <div
          :class="{ hiddenMenuEffect: openMenu }"
          @click="menuControl()"
          class="hamburger-menu"
        >
          <span class="stick"></span>
          <span class="stick"></span>
          <span class="stick"></span>
        </div>
        <ul :class="{ menu: openMenu }" class="navbar">
          <div @click="menuControl()" class="absolute right-3 top-7 lg:hidden">
            <span
              :class="{ animate: !openMenu }"
              class="w-[25px] h-[4px] bg-white absolute rotate-45"
            ></span>
            <span
              :class="{ animate: !openMenu }"
              class="w-[25px] h-[4px] bg-white block -rotate-45"
            ></span>
          </div>
          <li>
            <router-link to="/"
              ><a
                @click="setLinkData('home')"
                name="home"
                id="Home"
                data-txt="HOME"
                >HOME</a
              ></router-link
            >
          </li>
          <li>
            <router-link to="/"
              ><a
                @click="setLinkData('about-us')"
                data-txt="ABOUT US"
                name="about-us"
                id="aboutUs"
                >ABOUT US</a
              ></router-link
            >
          </li>
          <li>
            <router-link to="/"
              ><a
                @click="setLinkData('our-work')"
                data-txt="OUR WORK"
                name="our-work"
                id="ourWork"
                >OUR WORK</a
              ></router-link
            >
          </li>
          <li>
            <router-link to="/"
              ><a
                @click="setLinkData('services')"
                data-txt="SERVICES"
                name="services"
                id="serviceS"
                >SERVICES</a
              ></router-link
            >
          </li>
          <li>
            <router-link class="customContactLink" to="/"
              ><a
                @click="setLinkData('contact')"
                data-txt="CONTACT"
                name="contact"
                id="contacT"
                >CONTACT</a
              ></router-link
            >
          </li>
        </ul>
      </div>
    </header>

    <div id="content_page" class="content_page">
      <img
        class="max-w-full h-auto"
        src="../assets/developing-programmer-team-development-website-design-coding-technologies-working-software-company-office.png"
        alt=""
      />
      <div class="sec-page">
        <h3 class="sec-page-h3">Software Development</h3>
        <p class="sec-page-p">
          high performing and next gen applications by proving dedicated and
          skilful resources, with the flexibility to choose, manage and scale
          resources as per requirements Our experience software development team
          has gained significant insights through continuous training programs
          which benefits clients; businesses with high quality deliverables and
          faster project deadlines.
        </p>
        <div class="sec-page-links">
          <router-link to="/" class="sec-page-a">
            <img
              class="rotate-90 scale-75"
              src="../assets/Group64.svg"
              alt=""
            />
            Back</router-link
          >

          <router-link to="/cloud-desktop" class="sec-page-a">
            Learn More: Software Product Development<img
              class="-rotate-90 scale-75"
              src="../assets/Group64.svg"
              alt=""
            />
          </router-link>
        </div>
      </div>
    </div>

    <appFooter></appFooter>
  </div>
</template>

<script>
import appFooter from "@/components/footer-app.vue";
import { mapMutations } from "vuex";
export default {
  components: {
    appFooter,
  },
  data() {
    return {
      openMenu: false,
    };
  },
  methods: {
    ...mapMutations(["setLinkName", "setLinkClicked"]),
    menuControl() {
      this.openMenu = !this.openMenu;
    },
    setLinkData(elm) {
      this.setLinkName(elm);
      this.setLinkClicked();
    },
  },

  mounted() {
    let header = document.getElementById("header");
    let content_page = document.getElementById("content_page");
    content_page.style.marginTop = header.clientHeight + "px";
    window.scrollTo(0, 0);
  },
};
</script>
