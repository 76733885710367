<template>
  <appHeader></appHeader>
  <appAboutUs></appAboutUs>
  <appOurWork></appOurWork>
  <appServices></appServices>
  <!-- <appJoinContent></appJoinContent> -->
  <appContact></appContact>
</template>

<script>
import appAboutUs from "./aboutUs-app.vue";
import appContact from "./contact-app.vue";
//import appJoinContent from "./joinContent-app.vue";
import appOurWork from "./ourWork-app.vue";
import appServices from "./services-app.vue";
import appHeader from "./header-app.vue";

export default {
  components: {
    appContact,
    //appJoinContent,
    appServices,
    appOurWork,
    appAboutUs,
    appHeader,
  },
};
</script>
