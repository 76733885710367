<template>
  <div id="about-us" class="content">
    <div class="content-article">
      <h4
        class="text-[25px] md:text-[30px] font-semibold lg:text-[45px] lg:leading-[60px] mb-[17px] text-center lg:text-start"
      >
        Why Choose <br />
        MIMIKO Technology
      </h4>
      <p
        class="font-medium 3xl:text-[25px] md:text-[20px] text-[15px] text-center lg:text-start"
      >
        We are a Consummate Custom Software Development company delivering
        splendid business IT Solutions and related services to customers across
        the globe. Our development services are led by our dedicated and
        passionate team to provide best industry practices combined with
        Technology expertise and business domain knowledge to drive digital
        transformation. Our proficiency in understanding business challenges and
        Professional competence allows us to create a better experience for our
        customers.
      </p>
    </div>
    <div class="cards">
      <div class="cards-sec">
        <div class="card">
          <img src="../assets/html-system.png" alt="" />
        </div>
        <div class="card">
          <img src="../assets/Rectangle3.png" alt="" />
        </div>
        <div class="card">
          <img src="../assets/representations.png" alt="" />
        </div>
        <div class="card">
          <img src="../assets/ecommerce.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>
