<template>
  <div id="contact" class="contact-us">
    <h3
      class="font-semibold text-[30px] lg:text-[35px] xl:text-[40px] 2xl:text-[45px]"
    >
      Contact Us
    </h3>
    <div id="form_contact" class="contact-form">
      <div
        class="flex flex-col md:flex-row gap-y-[25px] lg:gap-y-[30px] md:gap-0 w-full md:gap-x-[20px] relative"
      >
        <div
          class="flex flex-col gap-y-[25px] lg:gap-y-[30px] w-full items-center"
        >
          <div class="w-full relative">
            <input
              @click="errorMessage = false"
              v-model="firstName"
              class="content-inputs"
              placeholder="First Name *"
              type="text"
            />
            <div
              v-show="errorMessage"
              class="flex flex-row gap-x-[20px] absolute -bottom-5 lg:-bottom-6 3xl:-bottom-7 left-0"
            >
              <span
                v-show="err.$property == 'firstName'"
                v-for="err in v$.$errors"
                :key="err.$uid"
                class="error-span"
              >
                <div class="block">
                  {{ err.$message }}
                </div>
              </span>
            </div>
          </div>

          <div class="w-full relative">
            <input
              @click="errorMessage = false"
              v-model="lastName"
              class="content-inputs"
              placeholder="Last Name *"
              type="text"
            />
            <div
              v-show="errorMessage"
              class="flex flex-row gap-x-[20px] absolute -bottom-5 lg:-bottom-6 3xl:-bottom-7 left-0"
            >
              <span
                v-show="err.$property == 'lastName'"
                v-for="err in v$.$errors"
                :key="err.$uid"
                class="error-span"
              >
                <div class="block">
                  {{ err.$message }}
                </div>
              </span>
            </div>
          </div>

          <div class="w-full relative">
            <input
              @click="errorMessage = false"
              v-model="eMail"
              class="content-inputs"
              placeholder="Email *"
              type="email"
            />
            <div
              v-show="errorMessage"
              class="flex flex-row gap-x-[20px] absolute -bottom-5 lg:-bottom-6 3xl:-bottom-7 left-0"
            >
              <span
                v-show="err.$property == 'eMail'"
                v-for="err in v$.$errors"
                :key="err.$uid"
                class="error-span"
              >
                <div class="block">
                  {{ err.$message }}
                </div>
              </span>
            </div>
          </div>
        </div>
        <div class="flex w-full justify-center relative">
          <textarea
            @click="errorMessage = false"
            v-model="message"
            placeholder="Message"
            style="height: 100%"
            class="content-inputs"
          ></textarea>
          <div
            v-show="errorMessage"
            class="flex flex-row gap-x-[20px] absolute -bottom-5 lg:-bottom-6 3xl:-bottom-7 left-0"
          >
            <span
              v-show="err.$property == 'message'"
              v-for="err in v$.$errors"
              :key="err.$uid"
              class="error-span"
            >
              <div class="block">
                {{ err.$message }}
              </div>
            </span>
          </div>
        </div>
      </div>

      <button
        @click="submitForm"
        class="content-btns bg-[#FC0A1A] text-white transition-all hover:bg-black duration-300"
        type="submit"
      >
        Submit
      </button>
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required, email, minLength } from "@vuelidate/validators";
import axios from "axios";
import { useToast } from "vue-toastification";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      firstName: "",
      lastName: "",
      eMail: "",
      message: "",
      errorMessage: false,
      toastServerMessage: useToast(),
    };
  },
  validations() {
    return {
      firstName: { required, minLength: minLength(3) },
      lastName: { required, minLength: minLength(2) },
      eMail: { required, email },
      message: { required },
    };
  },
  methods: {
    async submitForm() {
      const result = await this.v$.$validate();
      //console.log(result);
      this.trimForm();
      console.log(this.v$.$errors);
      if (result == false) {
        this.errorMessage = true;
      }

      let userdata = {
        firstname: this.firstName,
        lastname: this.lastName,
        email: this.eMail,
        message: this.message,
      };

      let jsonData = JSON.stringify(userdata);
      console.log(jsonData);

      if (this.v$.$errors.length == 0) {
        axios
          .post("http://54.37.75.5:9060/contact", jsonData)
          .then((response) => {
            console.log(response);
          })
          .catch((error) => {
            console.log(error);
            this.toastServerMessage.error("Server Error!", {
              position: "top-right",
              timeout: 3200,
              closeOnClick: false,
              pauseOnFocusLoss: true,
              pauseOnHover: false,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: false,
              closeButton: "button",
              icon: true,
              rtl: false,
            });
          });
      }
    },

    trimForm() {
      this.firstName = this.firstName.trim();
      this.lastName = this.lastName.trim();
      this.eMail = this.eMail.trim();
      this.message = this.message.trim();
    },
  },
};
</script>
