<template>
  <div id="services" class="serve-content">
    <h3
      class="text-center text-[35px] sm:text-[40px] md:text-[45px] lg:text-[55px] font-semibold underline_effect"
    >
      Industries We Serve
    </h3>
    <div class="serve-cards">
      <div class="serve-card">
        <img src="../assets/fintech.svg" alt="" />
        <p>Fin-Tech</p>
      </div>
      <div class="serve-card">
        <img src="../assets/presentation.svg" alt="" />
        <p>Education</p>
      </div>
      <div class="serve-card">
        <img src="../assets/check-in.svg" alt="" />
        <p>Hospitality</p>
      </div>
      <div class="serve-card">
        <img src="../assets/booking.svg" alt="" />
        <p>Travel, Booking</p>
      </div>
      <div class="serve-card">
        <img src="../assets/retail.svg" alt="" />
        <p>FMCG - Retail Shops</p>
      </div>
    </div>

    <app-join></app-join>
  </div>
</template>

<script>
import appJoin from "./joinContent-app.vue";

export default {
  components: {
    appJoin
  }
}

</script>


