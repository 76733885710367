<template>
  <router-view></router-view>
  <!-- <app-home></app-home> -->
</template>

<script>
//import appHome from "./components/home-app.vue";
export default {
  name: "app",
  components: {
    //appHome,
  },
};
</script>

<style>
* {
  box-sizing: border-box;
  
}

html,
body {
  width: 100%;
  height: 100%;
  scroll-behavior: smooth;
}


#app {
  width: 100%;
  height: 100%;
}
</style>
