<template>
  <footer class="bg-footer">
    <div class="footer">
      <a
        @click="upPage"
        id="up-arrow"
        class="fixed cursor-pointer invisible opacity-35 hover:opacity-100 transition-all bottom-[30px] 3xl:bottom-[80px] right-2 2xl:right-[47px] md:right-3 lg:right-5"
        ><img
          class="w-[80px] md:w-[100px] 2xl:w-[132px]"
          src="../assets/Group8.svg"
          alt=""
      /></a>

      <img
        class="w-[150px] md:w-[200px] 2xl:w-[234px]"
        src="../assets/mimiko-logo.png"
        alt=""
      />
      <div class="footer-address">
        <p class="lg:basis-[55%]">
          Address: C/O Unit 4 Kinetica, 13 Ramsgate Street, London, E8 2FD, UK
          Registration : 10399669
        </p>
        <p class="lg:basis-[45%]">
          E-Mail : info@mimikotechnology.com <br />
          Phone: +44 7535 494221
        </p>
      </div>
    </div>
    <p
      class="mt-[20px] 2xl:mt-[0] text-[12px] md:text-[15px] 2xl:text-[20px] text-center text-white"
    >
      © Copyrights 2016 MIMIKO Technology All rights reserved.
    </p>
  </footer>
</template>

<script>
export default {
  methods: {
    upPage() {
      scrollTo(0, 0)
    },
  },
};
</script>
