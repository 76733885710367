<template>
  <div id="container" class="w-full h-full relative">
    <!-- header kısmı -->
    <header id="header" class="head-container">
      <div class="flex items-center justify-end lg:justify-start">
        <img
          class="w-3/5 sm:w-4/5 h-auto"
          src="../assets/mimiko-logo.png"
          alt="logo"
        />
      </div>
      <div class="nav-content">
        <div
          :class="{ hiddenMenuEffect: openMenu }"
          @click="menuControl()"
          class="hamburger-menu"
        >
          <span class="stick"></span>
          <span class="stick"></span>
          <span class="stick"></span>
        </div>
        <ul :class="{ menu: openMenu }" class="navbar">
          <div @click="menuControl()" class="absolute right-3 top-7 lg:hidden">
            <span
              :class="{ animate: !openMenu }"
              class="w-[25px] h-[4px] bg-white absolute rotate-45"
            ></span>
            <span
              :class="{ animate: !openMenu }"
              class="w-[25px] h-[4px] bg-white block -rotate-45"
            ></span>
          </div>
          <li>
            <router-link to="/"
              ><a
                @click="scrollElement('home')"
                class="active"
                name="home"
                id="Home"
                data-txt="HOME"
                >HOME</a
              ></router-link
            >
          </li>
          <li>
            <router-link to="/"
              ><a
                @click="scrollElement('about-us')"
                data-txt="ABOUT US"
                name="about-us"
                id="aboutUs"
                >ABOUT US</a
              ></router-link
            >
          </li>
          <li>
            <router-link to="/"
              ><a
                @click="scrollElement('our-work')"
                data-txt="OUR WORK"
                name="our-work"
                id="ourWork"
                >OUR WORK</a
              ></router-link
            >
          </li>
          <li>
            <router-link to="/"
              ><a
                @click="scrollElement('services')"
                data-txt="SERVICES"
                name="services"
                id="serviceS"
                >SERVICES</a
              ></router-link
            >
          </li>
          <li>
            <a
              class="customContactLink"
              @click="scrollElement('contact')"
              data-txt="CONTACT"
              name="contact"
              id="contacT"
              >CONTACT</a
            >
          </li>
        </ul>
      </div>
    </header>

    <app-main></app-main>
    <!-- <appAboutUs></appAboutUs>
    <appOurWork></appOurWork>
    <appServices></appServices>
    <appJoinContent></appJoinContent>
    <appContact></appContact> -->
    <appFooter> </appFooter>

    <!-- <div v-show="true" class="h-[150px] w-full">
      <button class="w-20 h-20 bg-amber-600 text-red-700" @click="heightGet"> bas bana</button>
    </div> -->
  </div>
</template>

<script>
window.onbeforeunload = function () {
  console.log("burası onbeforeunload calisti");
  sessionStorage.setItem("scrollpos", window.scrollY);
};
window.addEventListener("DOMContentLoaded", function () {
  window.onload = () => {
    var scrollpos = sessionStorage.getItem("scrollpos");
    console.log("scrollpos : " + scrollpos);
    if (scrollpos) window.scrollTo(0, scrollpos);
  };
});

//scroll ayarları
function scrollOpacitySettings() {
  let header = this.document.getElementById("header");
  let headSecBg = this.document.getElementById("head-sec-background");
  let headSecContent = this.document.getElementById("head-sec-content");
  let scrollTop = window.scrollY || this.document.documentElement.scrollTop;
  headSecBg.style.opacity = 0.75 - scrollTop / 200;

  header.style.backgroundColor =
    "rgba(" + 252 + "," + 10 + "," + 26 + "," + (0 + scrollTop / 150) + ")";

  if (document.body.clientWidth >= 1024) {
    headSecContent.style.opacity = 1 - scrollTop / 200;
  }
}

function scrollAnchorSettings() {
  let sections = ["contact", "about-us", "services", "our-work", "home"];
  let navLinks = document.querySelectorAll("header ul li a");
  let header = document.getElementById("header");
  sections.forEach((sec) => {
    let elm = document.getElementById(sec);
    let top = window.scrollY + header.clientHeight;
    let offset = elm.offsetTop - header.clientHeight;
    let height = elm.offsetHeight;
    let id = elm.getAttribute("id");

    if (top >= offset && top < offset + height) {
      navLinks.forEach((link) => {
        //console.log(link);

        // console.log("detect : " + elm.getAttribute("id"));
        link.classList.remove("active");
        // document
        //   .querySelector("header ul li a[href*=" + id + "]")
        //   .classList.add("active");

        document.getElementsByName(id)[0].classList.add("active");
      });
    }
  });
}

import appFooter from "./footer-app.vue";
import appMain from "./main-app.vue";
import { mapMutations, mapGetters } from "vuex";
// import appContact from "./contact-app.vue";
// import appJoinContent from "./joinContent-app.vue";
// import appServices from "./services-app.vue";
// import appOurWork from "./ourWork-app.vue";
// import appAboutUs from "./aboutUs-app.vue";

export default {
  components: {
    appFooter,

    appMain,
    // appContact,
    // appJoinContent,
    // appServices,
    // appOurWork,
    // appAboutUs,
  },

  data() {
    return {
      openMenu: false,
      linkName: "",
    };
  },
  methods: {
    ...mapMutations(["setLinkName", "removeLinkClicked"]),
    ...mapGetters(["getLinkName", "getLinkClicked"]),
    // heightGet() {
    //   console.log('home kısım height : ' + document.getElementById('home').clientHeight);
    // },
    menuControl() {
      this.openMenu = !this.openMenu;
    },
    activeClss(elm) {
      localStorage.setItem("activeName", elm);
      this.activeName = localStorage.getItem("activeName");
    },
    activeLinks() {
      window.addEventListener("scroll", scrollAnchorSettings);
    },
    scrollElement(element) {
      this.setLinkName(element);
      let header = document.getElementById("header");
      let headerHeight = header.offsetHeight;
      let elm = document.getElementById(element);
      console.log(elm);
      console.log("element offsetTop : " + elm.offsetTop);
      let topPos = elm.offsetTop - headerHeight;

      console.log(elm.getAttribute("id") + " : " + topPos);

      window.scrollTo(0, topPos);
    },
    linkedElementScroll(isLinkClicked) {
      setTimeout(() => {
        if (isLinkClicked) {
          let elm = document.getElementById(this.getLinkName());
          let header = document.getElementById("header");
          let headerHeight = header.offsetHeight;
          let pos = elm.offsetTop - headerHeight;

          window.scrollTo(0, pos);

          this.removeLinkClicked();

          console.log("link is clicked : " + this.getLinkClicked());
        }
      }, 0.1);
    },

    upArrowFunc() {
      window.addEventListener("scroll", function () {
        let arrow = this.document.getElementById("up-arrow");
        if (this.scrollY > 150) {
          arrow.style.visibility = "visible";
        } else {
          arrow.style.visibility = "hidden";
        }
      });
    },
  },

  beforeUnmount() {
    window.removeEventListener("scroll", scrollOpacitySettings);
    window.removeEventListener("scroll", scrollAnchorSettings);
    sessionStorage.setItem('scrollpos-2', window.scrollY);

  },
  beforeMount() {
    console.log(this.getLinkName());
  },
  mounted() {
    this.activeLinks();
    window.addEventListener("scroll", scrollOpacitySettings);
    document.getElementById("head-sec-background").style.opacity = 0.75;
    document.getElementById("head-sec-content").style.opacity = 1;
    this.upArrowFunc();
    this.linkedElementScroll(this.getLinkClicked());
    console.log("home scrollpos : " + sessionStorage.getItem("scrollpos"));
    window.scrollTo(0, sessionStorage.getItem('scrollpos-2'));
  },
};
</script>
