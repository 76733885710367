<template>
  <div id="our-work" class="content-services">
    <h3
      class="text-[40px] md:text-[45px] lg:text-[55px] font-semibold text-center underline_effect"
    >
      Our Services
    </h3>
    <div id="services-cards" class="services-cards relative">
      <div class="services-card">
        <img class="mb-[20px] w-[113px]" src="../assets/coding.svg" alt="" />
        <h5 class="h5-font">Custom Software Development</h5>
        <p class="services-p">
          The custom software development process encompasses designing,
          developing, delaying, and maintaining custom software solutions and
          services.
        </p>
        <router-link to="/cloud-solutions" class="services-a"
          ><span class="hoverLearnMore">Learn More</span>
          <img class="w-[16px] mt-1 -rotate-90" src="../assets/Group64.svg" alt=""
        /></router-link>
      </div>

      <div class="services-card">
        <img class="mb-[20px]" src="../assets/programming.svg" alt="" />
        <h5 class="h5-font">Web Development</h5>
        <p class="services-p">
          Transforming your custom business ideas into an innovative next gen
          web app is our forte. With proven results and promising qualitative
          deliverables across
        </p>
        <router-link to="/managed-it-services" class="services-a"
          ><span class="hoverLearnMore">Learn More</span>
          <img class="w-[16px] mt-1 -rotate-90" src="../assets/Group64.svg" alt=""
        /></router-link >
      </div>

      <div class="services-card">
        <img class="mb-[20px]" src="../assets/cooperation.svg" alt="" />
        <h5 class="h5-font">Dedicated Development Team</h5>
        <p class="services-p">
          Expand your ability to cater your business goals efficiently by hiring
          our dedicated software development tea. Mimiko enables your business
          with customised,
        </p>
        <router-link to="/disaster-recovery" class="services-a"
          ><span class="hoverLearnMore">Learn More</span>
          <img class="w-[16px]  mt-1 -rotate-90" src="../assets/Group64.svg" alt=""
        /></router-link>
      </div>

      <div class="services-card">
        <img class="mb-[20px]" src="../assets/layers.svg" alt="" />
        <h5 class="h5-font">Software Product Development</h5>
        <p class="services-p">
          To convert your vision into reality, we assist you rom software
          product ideation to design, development, release, and maintenance
        </p>
        <router-link to="/cloud-desktop" class="services-a"
          ><span class="hoverLearnMore">Learn More</span>
          <img class="w-[16px] mt-1 -rotate-90" src="../assets/Group64.svg" alt=""
        /></router-link>
      </div>

      <div class="services-card relative">
        <img class="mb-[20px]" src="../assets/mobile-development.svg" alt="" />
        <h5 class="h5-font">Mobile App Development</h5>
        <p class="services-p">
          Ecommerce is one of the fastest growing industries so collaborating
          with one of the top ecommerce development companies becomes
        </p>
        <router-link to="/network-solutions" class="services-a"
          ><span class="hoverLearnMore">Learn More</span>
          <img class="w-[16px] mt-1 -rotate-90" src="../assets/Group64.svg" alt=""
        /></router-link>
      </div>

      <div class="services-card">
        <img class="mb-[20px]" src="../assets/Invoice.svg" alt="" />
        <h5 class="h5-font">E-Commerce Software</h5>
        <p class="services-p">
          Ecommerce is one of the fastest growing industries so collaborating
          with one of the top ecommerce development companies becomes
        </p>
        <router-link to="/support-consulting" class="services-a"
          ><span class="hoverLearnMore">Learn More</span>
          <img class="w-[16px] mt-1 -rotate-90" src="../assets/Group64.svg" alt=""
        /></router-link>
      </div>
    </div>
  </div>
</template>

