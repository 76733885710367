<template>
  <div id="home" class="head-sec relative">
    <img
      class="head-sec-img block "
      src="../assets/it-developer-close-up-2021-09-24-04-17-44-utc@2x.png"
      alt=""
    />
    <svg
      class="absolute top-0 left-0 max-w-full h-auto"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1920 1079.946"
    >
      <path
        class="w-full"
        id="head-sec-background"
        data-name="Path 13"
        d="M0,0H1920V410S556.966,1079.946,355.454,1079.946,0,880.711,0,880.711Z"
        fill="#fc0a1a"
        opacity="0.75"
      />
    </svg>
  </div>
  <div id="head-sec-content" class="head-sec-content">
    <h3
      class="text-[25px] md:text-[30px] lg:text-[25px] xl:text-[30px] 3xl:text-[40px] font-semibold mb-5"
    >
      MIMIKO Technology
    </h3>
    <p>
      We provide you with the talent and capabilities to execute your software
      projects. <br />
      MIMIKO Technology works with customers to build innovative products and
      services, <br />
      helping them nurture their ideas, staying with them during market
      validation and scaling <br />
      them into viable businesses. Their deep and varied technical expertise,
      complemented by <br />
      a customer-centric engagement model helps their customers craft solutions
      for critical business <br />
      and technological challenges.They are a partner to their customers,
      sharing their vision while <br />
      mitigating their risks.
    </p>
  </div>
</template>


