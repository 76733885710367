<template>
  <div class="join-content">
    <h3
      class="text-[35px] lg:text-[40px] 2xl:text-[55px] text-white underline decoration-3 underline-offset-4"
    >
      Join the Club
    </h3>
    <p
      class="text-white text-center text-[15px] lg:text-[20px] 2xl:text-[25px]"
    >
      Join our email list and get access to specials deals exclusive to our
      subscribers.
    </p>
    <form
      class="flex flex-col md:flex-row md:justify-center md:gap-x-[7px] gap-y-[20px] md:gap-y-0 items-center w-full relative"
    >
      <div
        class="w-full relative md:w-1/2 lg:w-2/5 xl:w-1/3 2xl:w-2/5 h-[40px] lg:h-[45px] xl:h-[50px] 2xl:h-[77px]"
      >
        <input
          @click="errorMessageShow = false"
          class="rounded-xl w-full h-[40px] lg:h-[45px] xl:h-[50px] 2xl:h-[77px] outline-none pl-5 placeholder:font-semibold 2xl:placeholder:text-[25px] 2xl:text-[25px]"
          placeholder="Email"
          type="email"
          v-model="email"
        />
        <span
          v-show="errorMessageShow"
          class="absolute text-white top-10 lg:top-12 xl:top-[50px] 2xl:top-20 text-[12px] lg:text-[15px] 2xl:text-[18px] left-0"
        >
          {{ errMsg }}</span
        >
      </div>
      <!-- <input @click="errorMessage = false" class="content-inputs" placeholder="Email" type="email" v-model="email" /> -->

      <button
        @click="joinBtn"
        type="button"
        class="content-btns text-[#FC0A1A] bg-white hover:text-white hover:bg-black transition-all duration-300"
      >
        Submit
      </button>
    </form>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import axios from "axios";
import { useToast } from "vue-toastification";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      email: "",
      errorMessageShow: false,
      errMsg: null,
      toastServerMessage: useToast(),
    };
  },
  validations() {
    return {
      email: { required, email },
    };
  },
  methods: {
    async joinBtn() {
      const result = await this.v$.$validate();
      console.log(result);
      console.log(this.v$.$errors);

      if (result == false) {
        this.errorMessageShow = true;
      }

      let emailJson = JSON.stringify({
        email: this.email,
      });

      if (this.v$.$errors.length == 0) {
        axios
          .post("http://54.37.75.5:9060/mailsubscription", emailJson)
          .then((response) => {
            console.log(response);
          })
          .catch((error) => {
            console.log(error);
            this.toastServerMessage.error("Server Error!", {
              position: "top-right",
              timeout: 3200,
              closeOnClick: false,
              pauseOnFocusLoss: true,
              pauseOnHover: false,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: false,
              closeButton: "button",
              icon: true,
              rtl: false,
            });
          });
      } else {
        this.v$.$errors.forEach((element) => {
          this.errMsg = element.$message;
        });
      }
    },
  },
};
</script>
