import { createStore } from "vuex";


const store = createStore({
    namespaced: true,

    state(){
        return{
            linkName: 'home',
            isLinkClicked: false
        }
    },

    mutations: {
        setLinkName(state, link){
            state.linkName = link;
            sessionStorage.setItem('linkName', state.linkName);
    
           console.log(sessionStorage.getItem('linkName'));
        },
        setLinkClicked(state){
            state.isLinkClicked = true;

        },
        removeLinkClicked(state){
            state.isLinkClicked = false;
        }

    },
    getters: {
        getLinkName(state){
            console.log('getters calisti');

            if (sessionStorage.getItem('linkName') == null) {
                sessionStorage.setItem('linkName', state.linkName);
                return sessionStorage.getItem('linkName');
            }else{
                return sessionStorage.getItem('linkName');
            }
            
        },

        getLinkClicked(state){
            return state.isLinkClicked;
        }
    }
})


export default store;