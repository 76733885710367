import { createRouter, createWebHashHistory } from "vue-router";

//import mainApp from "./components/main-app.vue";
import homeApp from "./components/home-app.vue";
import appCSD from "./pages/csd-app.vue";
import appWD from "./pages/wd-app.vue";
import appDDT from './pages/ddt-app.vue';
import appSPD from './pages/spd-app.vue';
import appMAD from './pages/mad-app.vue';
import appECS from './pages/ecs-app.vue';


const routes = [
    {
        path: '/',
        mode: history,
        component: homeApp,

    },
    {
        path: '/cloud-solutions',
        mode: history,
        component: appCSD,
    },
    {
        path: '/managed-it-services',
        mode: history,
        component: appWD
    },
    {
        path: '/disaster-recovery',
        mode: history,
        component: appDDT
    },
    {
        path: '/cloud-desktop',
        mode: history,
        component: appSPD
    },
    {
        path: '/network-solutions',
        mode: history,
        component: appMAD
    },
    {
        path: '/support-consulting',
        mode: history,
        component: appECS
    },
    

];


const router = createRouter({
    routes,
    history: createWebHashHistory()
})

export default router;